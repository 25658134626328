<template>
  <div>
    <div class="row">
      <div class="col-12 col-md-6">
        <h4 class="f-bold pt-2 pl-3 cl-primary text-left" v-if="!id">
          Add Banner
        </h4>
        <h4 class="f-bold pt-2 pl-3 cl-primary text-left" v-if="id">
          Edit Banner
        </h4>
      </div>
      <div class="col-12 col-md-6">
        <nav aria-label="breadcrumb">
          <ol
            class="breadcrumb float-right"
            style="background-color: transparent"
          >
            <li class="breadcrumb-item">
              <router-link to="/admin/dashboard">Dashboard</router-link>
            </li>
            <li class="breadcrumb-item">
              <router-link to="/admin/banners">Data Banners</router-link>
            </li>
            <li class="breadcrumb-item active" aria-current="page">
              Data Banners
            </li>
          </ol>
        </nav>
      </div>
    </div>
    <div class="d-flex justify-content-around" v-if="loading">
      <loader-component></loader-component>
    </div>
    <form @submit.prevent="submit" v-if="!loading">
      <div class="row">
        <div class="col-md-6">
          <div class="form-group">
            <input
              type="text"
              class="form-control"
              id="title"
              aria-describedby="title"
              placeholder="Title"
              v-model="form.title"
            />
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group">
            <input
              type="text"
              class="form-control"
              id="subtitle"
              aria-describedby="subtitle"
              placeholder="Subtitle"
              v-model="form.subtitle"
            />
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <img
            :src="'https://api-dev.mimologistics.id/banner/image/' + form.id"
            class="img-thumbnail"
            v-if="id"
          />
        </div>
        <div class="col-md-12">
          <div class="form-group text-left">
            <label for="Image" class="text-secondary text-left">Image</label>
            <input
              type="file"
              @change="handleUploadImage()"
              class="form-control-file"
              ref="images"
              id="images"
            />
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div class="d-flex justify-content-end">
            <button class="btn btn-primary px-5" type="submit" v-if="!id">
              Save
            </button>
            <button class="btn btn-primary px-5" type="submit" v-if="id">
              Update
            </button>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>
<script>
import Vue from "vue";
import LoaderComponent from "../../components/Loader.vue";
export default {
  props: ["id"],
  components: { LoaderComponent },
  data() {
    return {
      loading: false,
      form: {
        title: "",
        subtitle: "",
        image: "",
        id: "",
      },
    };
  },

  mounted() {
    if (this.id) {
      this.getBanner();
    }
  },
  methods: {
    submitBanner() {
      Vue.swal({
        title: "Are you sure?",
        text: "Your data will be entered",
        icon: "question",
        showCancelButton: true,
        cancelButtonColor: "#eb4d4b",
        confirmButtonText: "Yes, Submit Banner!",
      }).then((result) => {
        if (result.isConfirmed) {
          let formData = new FormData();
          this.loading = true;
          formData.append("title", this.form.title);
          formData.append("subtitle", this.form.subtitle);
          formData.append("image", this.form.image);
          this.$store
            .dispatch("postBanner", formData)
            .then((res) => {
              this.loading = false;
              console.log(res);
              this.resetForm();
              Vue.swal(
                res.data.status,
                "Your data is already added",
                "success"
              ).then(() => {
                this.toList();
              });
            })
            .catch((err) => {
              let errorMsg = "";
              this.loading = false;
              Object.keys(err.response.data).forEach(function (key) {
                let errorItem = key.charAt(0).toUpperCase() + key.slice(1);
                let errorCause = "";
                let errorLists = err.response.data[key];
                for (var prop in errorLists) {
                  errorCause += "<li>" + errorLists[prop] + "</li>";
                }
                errorMsg +=
                  "<ul style='list-style: none; text-align: left;' class='text-left'><b>" +
                  errorItem +
                  "</b>" +
                  errorCause +
                  "</ul><br>";
              });

              Vue.swal({
                icon: "error",
                title: "Error!",
                html: errorMsg,
              });
              console.log(err.response.data);
            });
        }
      });
    },

    submit() {
      if (this.id) {
        this.updateBanner();
      } else {
        this.submitBanner();
      }
    },

    resetForm() {
      (this.form.title = ""),
        (this.form.subtitle = ""),
        (this.form.image = null);
    },

    handleUploadImage() {
      this.form.image = this.$refs.images.files[0];
      console.log(this.form.image);
    },

    toList() {
      this.$router.push("/admin/banners");
    },

    getBanner() {
      this.loading = true;
      const endpoint = "banner/" + this.id;
      this.$api.get(endpoint, (status, data, message) => {
        console.log(status);
        if (status == 200) {
          this.loading = false;
          this.form = data.banner;
          console.log(data);
        } else {
          this.loading = false;
          console.log(message);
        }
      });
    },

    updateBanner() {
      Vue.swal({
        title: "Are you sure?",
        text: "Your data will be updated",
        icon: "question",
        showCancelButton: true,
        cancelButtonColor: "#eb4d4b",
        confirmButtonText: "Yes, Update Banner!",
      }).then((result) => {
        if (result.isConfirmed) {
          let formData = new FormData();
          this.loading = true;
          //   formData.append("id", this.form.id);
          formData.append("title", this.form.title);
          formData.append("subtitle", this.form.subtitle);
          formData.append("image", this.form.image);
          this.$store
            .dispatch("updateBanner", formData, this.id)
            .then((res) => {
              this.loading = false;
              console.log(res);
              this.resetForm();
              Vue.swal(
                res.data.status,
                "Your data is already added",
                "success"
              ).then(() => {
                this.toList();
              });
            })
            .catch((err) => {
              let errorMsg = "";
              this.loading = false;
              Object.keys(err.response.data).forEach(function (key) {
                let errorItem = key.charAt(0).toUpperCase() + key.slice(1);
                let errorCause = "";
                let errorLists = err.response.data[key];
                for (var prop in errorLists) {
                  errorCause += "<li>" + errorLists[prop] + "</li>";
                }
                errorMsg +=
                  "<ul style='list-style: none; text-align: left;' class='text-left'><b>" +
                  errorItem +
                  "</b>" +
                  errorCause +
                  "</ul><br>";
              });

              Vue.swal({
                icon: "error",
                title: "Error!",
                html: errorMsg,
              });
              console.log(err.response.data);
            });
        }
      });
    },
  },
};
</script>
